import(/* webpackMode: "eager" */ "/home/runner/work/fuzionest_website_next/fuzionest_website_next/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/fuzionest_website_next/fuzionest_website_next/components/layout/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoadingProvider"] */ "/home/runner/work/fuzionest_website_next/fuzionest_website_next/components/LoadingContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeWrapper"] */ "/home/runner/work/fuzionest_website_next/fuzionest_website_next/components/theme-wrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/fuzionest_website_next/fuzionest_website_next/components/ThemeToaster/ThemeToaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarProvider"] */ "/home/runner/work/fuzionest_website_next/fuzionest_website_next/components/ui/sidebar.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/fuzionest_website_next/fuzionest_website_next/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/fuzionest_website_next/fuzionest_website_next/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../public/Inter/Inter-VariableFont_opsz,wght.ttf\",\"variable\":\"--font-inter\",\"weight\":\"100 900\",\"display\":\"swap\"}],\"variableName\":\"interVariable\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/fuzionest_website_next/fuzionest_website_next/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../public/Reckless/RecklessNeue-Regular.ttf\",\"variable\":\"--font-reckless\",\"weight\":\"100 900\",\"display\":\"swap\"}],\"variableName\":\"RecklessNeueSemiBold\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/fuzionest_website_next/fuzionest_website_next/node_modules/@uploadthing/react/dist/index.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/fuzionest_website_next/fuzionest_website_next/Providers/TanstackProvider.tsx");
