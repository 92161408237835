// components/LoadingSpinner.tsx

'use client';

import React from 'react';

const LoadingSpinner: React.FC = () => {
    return (
        <div className="fixed inset-0 z-50 flex flex-col items-center justify-center bg-white">
            <svg
                width="80"
                height="80"
                viewBox="0 0 80 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mb-8 animate-pulse"
            >
                <path
                    d="M40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0ZM40 60C28.9543 60 20 51.0457 20 40C20 28.9543 28.9543 20 40 20C51.0457 20 60 28.9543 60 40C60 51.0457 51.0457 60 40 60Z"
                    fill="#2563EB"
                />
                <circle cx="40" cy="40" r="10" fill="#2563EB" />
            </svg>
            <p className="text-gray-600 text-lg text-center px-4">
                Please wait while we make everything perfect for you...
            </p>
        </div>
    );
};

export default LoadingSpinner;
