import React, { useState, useRef, useEffect } from 'react';
import { cn } from '@/lib/utils';
import InfoOutlineIcon from '@rsuite/icons/InfoOutline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/components/ui/tooltip';
import { TooltipViwer } from '../Tooltip';

interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'pattern'> {
  pattern?: string;
  minLength?: number;
  maxLength?: number;
  type?: string;
  info?: string;
  form?: any;
  NumberWithMax?: number;
  name?: string;
  onClear?: boolean;
  placeholderColor?: string
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type = 'text',
      pattern,
      disabled,
      minLength,
      maxLength,
      name,
      NumberWithMax,
      info,
      form,
      onClear,
      placeholderColor,
      ...props
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState<boolean>(false); // State to toggle password visibility

    const inputRef = useRef<HTMLInputElement>(null);

    const onlyNumber = (value: any, max: any) => {
      let numericValue = value.replace(/[^\d]/g, ''); // Remove non-numeric characters

      if (numericValue) {
        let num = parseInt(numericValue, 10);
        if (num > max) {
          numericValue = max.toString();
        }
      }
      return numericValue;
    };

    const handleInputChange = (e: any) => {
      const inputValue = e.target.value;
      let filteredValue = inputValue;

      switch (pattern) {
        case 'Text':
          filteredValue = inputValue.replace(/^\s+|[^A-Za-z]+/g, ''); // Allow alphabetical letters and space
          break;
        case 'Email':
          filteredValue = inputValue
            .replace(/[^A-Za-z0-9@._-]/g, '')
            .toLowerCase(); // Allow email characters
          break;
        case 'Number':
        case 'number':
          filteredValue = inputValue.replace(/[^\d]/g, ''); // Allow only number
          break;
        case 'NumberWithMax':
          filteredValue = onlyNumber(inputValue, NumberWithMax);
          break;
        case 'GST':
          filteredValue = inputValue.replace(/[^A-Za-z0-9]/g, '').toUpperCase(); // Allow only letters and numbers
          break;
        case 'URL':
          filteredValue = inputValue.replace(
            /[^A-Za-z0-9!@#$%^&*_+\-=;:'",./?]/g,
            ''
          );
          break;
        case 'Decimal': {
          filteredValue = inputValue.replace(/[^\d.]/g, '');

          const parts = filteredValue.split('.');

          if (parts.length > 2) {
            // More than one dot found, remove extra dots
            const integerPart = parts.shift(); // Remove the first part (before the first dot)
            const decimalPart = parts.join(''); // Join remaining parts together (after the first dot)
            filteredValue = integerPart + '.' + decimalPart; // Concatenate them back with a dot
          } else {
            parts[0] = parts[0].slice(0, 9);

            if (parts[1]) {
              parts[1] = parts[1].slice(0, 2);
            }
            filteredValue = parts.join('.');
          }
          break;
        }
        case 'NegativeWithDecimal': {
          filteredValue = inputValue.replace(/(?!^-)[^0-9.]/g, '');

          const parts = filteredValue.split('.');

          if (parts.length > 2) {
            // More than one dot found, remove extra dots
            const integerPart = parts.shift(); // Remove the first part (before the first dot)
            const decimalPart = parts.join(''); // Join remaining parts together (after the first dot)
            filteredValue = integerPart + '.' + decimalPart; // Concatenate them back with a dot
          } else {
            parts[0] = parts[0].slice(0, 9);

            if (parts[1]) {
              parts[1] = parts[1].slice(0, 2);
            }
            filteredValue = parts.join('.');
          }
          break;
        }
        case 'Double': {
          filteredValue = inputValue.replace(/[^\d.]/g, '');

          const parts = filteredValue.split('.');

          if (parts.length > 2) {
            // More than one dot found, remove extra dots
            const integerPart = parts.shift(); // Remove the first part (before the first dot)
            const decimalPart = parts.join(''); // Join remaining parts together (after the first dot)
            filteredValue = integerPart + '.' + decimalPart; // Concatenate them back with a dot
          } else {
            parts[0] = parts[0].slice(0, 9);

            if (parts[1]) {
              parts[1] = parts[1].slice(0, 2);
            }
            filteredValue = parts.join('.');
          }
          break;
        }
        case 'latlng': {
          filteredValue = inputValue.replace(/[^\d.]/g, '');
          break;
        }
        case 'Text With Space':
          filteredValue = inputValue.replace(/^\s+|[^A-Za-z\s]+/g, ''); // Allow alphabetical letters and space
          break;
        case 'Text Number With Space':
          filteredValue = inputValue.replace(/^\s+|[^A-Za-z0-9\s]+/g, ''); // Allow alphabetical letters and space
          break;
        case 'Text Number':
          filteredValue = inputValue.replace(/[^A-Za-z0-9]/g, ''); // Allow only letters and numbers
          break;
        case 'Upper Case':
          filteredValue = inputValue
            .replace(/^\s+|[^A-Za-z\s]+/g, '')
            .toUpperCase(); // Allow alphabetical letters and space
          break;
        case 'Lower Case':
          filteredValue = inputValue
            .replace(/^\s+|[^A-Za-z\s]+/g, '')
            .toLowerCase(); // Allow alphabetical letters and space
          break;
        case 'Init Caps':
          filteredValue =
            inputValue
              .replace(/^\s+|[^A-Za-z\s]+/g, '')
              .charAt(0)
              .toUpperCase() +
            inputValue
              .replace(/^\s+|[^A-Za-z\s]+/g, '')
              .slice(1)
              .toLowerCase(); // Allow alphabetical letters and space
          break;
        default:
          // filteredValue = inputValue;
          filteredValue = pattern ? inputValue.replace(pattern || /[^A-Za-z0-9]/g, '') : inputValue; // Use the provided pattern or default
          break;
      }
      e.target.value = filteredValue;
    };

    //     useEffect(()=>{
    //       if(form.getValues(name)&&(pattern=='Number'||pattern==="Decimal")&&typeof form.getValues(name)==='string') {
    //         const filteredValue = form.getValues(name).replace(/[^\d.]/g, '');
    // const floatValue = Number(filteredValue);
    //         form.setValue(name,floatValue);
    //       }
    //           },[form.getValues(name)])

    // const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //   const inputValue = e.target.value;
    //   let filteredValue = inputValue;

    //   if (pattern) {
    //     filteredValue = inputValue.replace(pattern, '').slice(0,maxLength);
    //   }
    //   e.target.value = filteredValue.slice(0,maxLength);
    // };

    const handleClearInput = () => {
      form?.setValue(name, '');
    };

    const handleChangeNumber = () => {
      if (
        form?.getValues(name) &&
        (pattern == 'Number' ||
          pattern == 'number' ||
          pattern === 'Decimal' ||
          pattern === 'Double') &&
        typeof form.getValues(name) === 'string'
      ) {
        var floatValue = parseFloat(form.getValues(name));
        form.setValue(name, floatValue);
        form.clearErrors(name);
      }
    };

    return (
      <div className="relative flex items-center space-x-2">
        {/* <div className="relative"> */}
        <input
          type={
            type === 'password' ? (showPassword ? 'text' : 'password') : type
          }
          minLength={minLength}
          disabled={disabled}
          maxLength={maxLength}
          className={cn(
            'h-10 w-full flex-1 border border-input bg-background placeholder-[#b1b1b1] px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium hover:border hover:shadow focus:shadow-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-[-1px] disabled:cursor-not-allowed disabled:opacity-50',
            className
          )}
          ref={ref}
          // ref={(element: HTMLInputElement | null) => {
          //   if (forwardedRef) {
          //     if (typeof forwardedRef === 'function') {
          //       forwardedRef(element);
          //     } else {
          //       (forwardedRef as React.MutableRefObject<HTMLInputElement | null>).current = element;
          //     }
          //   }
          //   inputRef.current = element;
          // }}/
          onInput={handleInputChange}
          // onPointerOut={handleChangeNumber}
          // onChangeCapture={handleChangeNumber}
          // onInputCapture={handleChangeNumber}
          // onKeyDown={handleChangeNumber}
          // onKeyDownCapture={handleChangeNumber}
          onMouseLeave={handleChangeNumber}
          // onMouseOut={handleChangeNumber}
          // onMouseOutCapture={handleChangeNumber}
          // onPointerDown={handleChangeNumber}
          // onPointerDownCapture={handleChangeNumber}
          // onPointerLeave={handleChangeNumber}
          // onPointerLeaveCapture={handleChangeNumber}
          // onPointerOutCapture={handleChangeNumber}
          {...props}
        />
        {type === 'password' && (
          <span
            onClick={() => setShowPassword((prev) => !prev)}
            className="absolute right-6 top-1/2 -translate-y-1/2 transform cursor-pointer"
          >
            {showPassword ? (
              <FontAwesomeIcon icon={faEye} />
            ) : (
              <FontAwesomeIcon icon={faEyeSlash} />
            )}
          </span>
        )}
        {/* </div> */}
        {/* {info && (
          <TooltipViwer info={info} />
        )} */}
        {/* <span
  onClick={handleClearInput}
  className="absolute top-1/2 transform -translate-y-1/2 right-6 cursor-pointer text-gray-400"
>
  {onClear===false ? "" : form?.getValues(name)?.length === 0 ? "" : "x"}
</span> */}
      </div>
    );
  }
);
Input.displayName = 'Input';

export { Input };
