// components/ThemeToaster.tsx

'use client';

import { useEffect, useState } from 'react';
import { Toaster } from 'sonner';

const ThemeToaster = () => {
  const [theme, setTheme] = useState<'light' | 'dark' | 'system'>('light');

  useEffect(() => {
    // Function to load theme from localStorage
    const loadTheme = () => {
      const storedTheme = localStorage.getItem('theme') as
        | 'light'
        | 'dark'
        | 'system'
        | null;
      setTheme(storedTheme || 'light');
    };

    loadTheme();

    // Optional: Listen to storage changes (for example, if the theme is changed in another tab)
    const handleStorageChange = () => {
      loadTheme();
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <Toaster
      richColors={true}
      position="top-right"
      visibleToasts={1}
      duration={2000}
      theme={theme}
    />
  );
};

export default ThemeToaster;
