// components/LoadingContext.tsx

'use client';

import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { usePathname } from 'next/navigation';
import LoadingSpinner from './LoadingSpinner';

interface LoadingContextProps {
  isLoading: boolean;
  setIsLoading: (state: boolean) => void;
}

const LoadingContext = createContext<LoadingContextProps>({
  isLoading: false,
  setIsLoading: () => {},
});

export const useLoading = () => useContext(LoadingContext);

export const LoadingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const pathname = usePathname();
  const [isLoading, setIsLoading] = useState(false);
  const [prevPath, setPrevPath] = useState<string | null>(null);

//   useEffect(() => {
//     if (prevPath && pathname !== prevPath) {
//       setIsLoading(true);
//       // Simulate loading time or integrate with actual data fetching
//       const timeout = setTimeout(() => {
//         setIsLoading(false);
//       }, 500); // Adjust the delay as needed

//       return () => clearTimeout(timeout);
//     }

//     setPrevPath(pathname);
//   }, [pathname, prevPath]);

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
      {isLoading && <LoadingSpinner />}
    </LoadingContext.Provider>
  );
};
